.social-links {
  display: flex;
}

.social-links__item {
  display: block;
  height: rem(18px);
  width: rem(18px);

  &:not(:last-child) {
    margin-right: rem(18px);
  }

  svg {
    color: $grey-dark;
  }
}
