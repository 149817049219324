$border-bottom-color: #93a9ff;

.vendor-search {
  @extend %container;
  color: $white;
  text-align: left;
}

.vendor-search__datepicker {
  display: inline-block;

  input {
    border-bottom: 2px dotted $border-bottom-color;
  }
}

.vendor-search__postcode {
  position: relative;
  display: inline-block;

  input {
    width: auto;
    border-bottom: 2px dotted $border-bottom-color;

    &::placeholder {
      color: transparentize($white, 0.6);
    }
  }

  .postcode__icon {
    top: rem(25px);
  }
}
