.magazine-card {
  @include breakpoint($phablet $laptop) {
    @include columns(6);
  }
  @include breakpoint($laptop) {
    @include columns(4);
  }
  display: block;
  border-bottom: 1px solid $grey-light;
  margin-bottom: rem(60px);
  transition: border-bottom-color $transition-medium ease;

  &:hover {
    border-bottom-color: transparent;
  }
}

.magazine-card__title {
  font-size: 22px;
  line-height: rem(27px);
  margin-bottom: rem(16px);
}

.magazine-card__meta {
  background: $white;
  position: relative;
  z-index: index($z-index, magazine-meta);
  width: calc(100% - #{rem(40px)});
  padding: rem(15px);
  margin: rem(-45px) auto 0;
  transition: box-shadow $transition-medium ease;

  .magazine-card:hover & {
    box-shadow: 0 2px 40px 0 rgba(153, 155, 168, 0.3);
  }
}

.magazine-card__thumb {
  margin: 0;
  background: $blue-light;
  height: 0;
  padding-bottom: 63.5%;
}
