.images-thumbnails {
  margin-bottom: 20px;
}

.images-thumbnails__images {
  display: flex;

  .icon {
    display: block;
    opacity: 0.35; // idea :hover colour change using fill: currentColor to manipulate SVG.
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: -17px;
    margin-right: 17px;

    &:hover {
      opacity: 1;
    }
  }
}

.image-item {
  margin-right: 10px;
  border-radius: 50%;
}

.image-uploader {
  display: inline-flex;
  align-items: flex-end;
  vertical-align: middle;
  margin-bottom: 10px;

  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      background: $grey-medium;
    }
  }
}

.image-draggable {
  display: flex;
  width: 105px;
  position: relative;

  &-remove {
    position: absolute;
    right: 0;
    top: 0;
  }
}
