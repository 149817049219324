.form-footer {
  margin-top: rem(45px);

  .chevron-link,
  p {
    font-size: em(14px);
  }

  p {
    margin-bottom: 0;
  }
}

.form-footer--centered {
  text-align: center;

  .button {
    margin-bottom: rem(20px);
  }
}

.form-footer--spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
