@use "sass:math";

// A space for prototyping before refactoring into
// completed partials

.caption {
  @extend %container;
  padding-top: rem(100);
  padding-bottom: rem(100);
  text-align: center;

  p:last-child {
    margin-bottom: 0;
  }
}

.caption__inner {
  max-width: rem(620px);
  margin: 0 auto;
}

.results {
  @extend %unstyled-list;
  background: $grey-light;
  padding-top: rem(40);
  padding-bottom: rem(40);
  display: flex;
  flex-wrap: wrap;
}

.section__col-2-3 {
  $padding: math.div($gutter, $total-width);
  @include breakpoint($tablet) {
    width: calc(100% - #{rem($booking-widget-width)});
    padding-right: $padding * 100%;
  }
}

.menu {
  margin-bottom: rem(40px);
  @include breakpoint($tablet) {
    margin-bottom: rem(60px);
  }
}

.view--bookings {
  h2 {
    color: $grey-dark;
    font-size: em(16px);
    margin-bottom: rem(30px);
  }

  .grid {
    margin-bottom: rem(20px);
  }

  .text-danger,
  .text-success {
    margin-left: 20px;
  }

  .action-bar {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.view--confirmation {
  .container {
    max-width: 660px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1,
  p {
    text-align: center;
  }

  h1 + p {
    margin-bottom: rem(40px);
  }
}
