.pricing {
  font-size: em(14px);
  width: 100%;
  color: $grey-dark;
  border-bottom: 1px solid $blue-light;
  padding-bottom: rem(10px);
  margin-bottom: rem(20px);

  tr {
    td:last-child {
      text-align: right;
    }
  }

  tr:last-child {
    color: $blue-dark;
    font-weight: 900;
  }
}
