.supplier-recommendation {
  img {
    border-radius: 50%;
  }
}

.supplier-recommendation__buttons {
  text-align: right;
}

.supplier-recommendation__error {
  color: $red;
  font-size: em(13px);
}

.supplier-recommendation-image__label {
  margin: rem(4px);
  cursor: pointer;

  &--selected {
    img {
      border: blue rem(2px) solid;
    }
  }
}

.supplier-recommendation-image {
  input[type='radio'] {
    display: none;
  }
}
