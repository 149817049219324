.shell {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
}

.shell::before {
  @extend %pseudo-element;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity $transition-medium ease;
  pointer-events: none;
  z-index: index($z-index, shade);
  background: rgba($blue-dark, 0.8);
  opacity: 0;
}

.shell--shade-modal::before {
  opacity: 1;
}

.shell--shade-mobile::before {
  @include breakpoint(max-width $phablet) {
    opacity: 1;
  }
}
