@use "sass:math";

@mixin columns($cols) {
  $width: math.div(($col * $cols) + ($gutter * ($cols - 1)), $total-width);
  $margin: math.div($gutter, $total-width);
  $nth: math.round(math.div(12, $cols));
  width: $width * 100%;
  margin-right: $margin * 100%;
  // margin-bottom: $margin * 100%;
  &:last-of-type,
  &:nth-child(#{$nth}n + #{$nth}) {
    margin-right: 0;
  }
}

// To be used as such:
//
// .col-1-3 {
//   @include columns(4);
// }
//
// .col-1-4 {
//   @include columns(3);
// }
