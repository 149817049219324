*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  outline: none;
}

html {
  font-size: rem(16px);
  color: $blue-dark;
}

body {
  line-height: 1.8em;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

svg {
  display: block;
}

a {
  @include link-colors($blue-dark, $blue-medium, $blue-dark, $blue-dark);
  text-decoration: none;
}

hr {
  height: 1px;
  background: $grey-medium;
  border: 1px solid;
}

input {
  border-radius: 0;
  background: none;
  border: none;

  &::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity $transition-medium ease;
  }

  &:focus::-webkit-input-placeholder {
    opacity: 0.5;
  }

  &[type='password'] {
    letter-spacing: 2px;
  }
}

input[type='email']:not(.form-control),
input[type='password']:not(.form-control),
input[type='text']:not(.form-control) {
  color: inherit;
  display: block;
  width: 100%;
  border-bottom: 1px solid $blue-dark;
  line-height: rem(38px);
}

.uploaded-image {
  width: 100px;
  height: 100px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-radius: 5px;
  margin-right: 5px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-left-25 {
  margin-left: 25px;
}

.red {
  color: red;
}
