.u-text-center {
  text-align: center;
}

.u-unstyled-btn {
  background: none;
  border: none;
  padding: 0;
}

.u-touch-btn {
  @extend .u-unstyled-btn;
  background-color: transparent;
  box-shadow: none;
  font-size: 0;
  outline: none;
  display: flex;
  appearance: none;
  justify-content: center;
  width: rem(44px);
  height: rem(44px);
}

.u-relative {
  position: relative;
}

.u-margin-bottom {
  margin-bottom: rem(20px);
}

.u-button-group {
  margin-left: auto;

  .button:not(:last-child) {
    margin-right: rem(20px);
  }
}

.u-columns {
  @extend %unstyled-list;
  @include breakpoint($mobile $phablet) {
    columns: 2;
  }
  @include breakpoint($phablet) {
    columns: 3;
  }

  > li {
    break-inside: avoid-column;
  }
}

.u-mb--s {
  margin-bottom: rem(20px);
}

.u-mb--0 {
  margin-bottom: 0;
}

.u-pl--0 {
  padding-left: 0;
}

.u-slide-down {
  max-height: rem(800px);
  transition: max-height $transition-long ease;
  overflow: hidden;
}

.u-slide-down--closed {
  max-height: 0;
}

.u-float-right {
  float: right;
}

.u-mt--s {
  margin-top: 10px !important;
}

.u-mt--l {
  margin-top: 20px !important;
}

.u-align--r {
  margin-left: auto;
}
