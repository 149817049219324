.mobile-menu {
  z-index: index($z-index, mobile-menu);
  width: rem(250px);
  position: fixed;
  top: 0;
  height: 100%;
  right: rem(-250px);
  background: $white;
  padding: rem(52px) rem(20px) $baseline;
  transition: transform $transition-medium ease;
  @media screen and (max-height: rem(500px)) {
    overflow-y: scroll;
  }
}

.mobile-menu--active {
  box-shadow: rem(-2px) 0 rem(20px) 0 $blue-dark;
  transform: translateX(-100%);
}

.mobile-menu__close {
  @extend .u-touch-btn;
  position: absolute;
  top: rem(7px);
  right: rem(7px);
}

.mobile-menu__nav-primary {
  margin-bottom: rem(46px);

  a {
    display: block;
    margin-bottom: $baseline;
  }
}

.mobile-menu__nav-secondary {
  columns: 2;
  font-size: em(14px);

  a {
    display: block;
    color: $grey-dark;
  }
}
