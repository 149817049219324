.basket-item {
  display: flex;
  justify-content: space-between;
  padding: rem(15px) 0;
  border-bottom: 1px solid $blue-light;
}

.basket-item__name {
  font-size: em(14px);
}

.basket-item__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: rem(80px);
}

.basket-item__button {
  @extend .u-unstyled-btn;
  cursor: pointer;
  height: rem(20px);
  width: rem(20px);
  text-align: center;
  line-height: rem(20px);
  border-radius: 2px;
  background: $grey-medium;
  outline: none;
}

.basket-item__button--disabled {
  background: #ececf0;
  cursor: not-allowed;
  // pointer-events: none;
}
