.checkbox_button {
  input[type='checkbox'] {
    display: none;
  }
}

.imageLabel {
  input[type='radio'] {
    display: none;
  }
}
.selected_image {
  margin: rem(20px) 0;
  h5 {
    margin-bottom: rem(10px);
  }
}

.feast_it_says--label {
  color: #999ba8;
  font-size: rem(13px);
}

.imagesWrapper {
  display: flex;
  // flex-wrap: wrap;
  .imageLabel {
    margin: rem(4px);
    cursor: pointer;
  }
  .imageLable-selected {
    img {
      border: blue rem(2px) solid;
    }
  }
}

.feast_it_says--image {
  &:hover {
    box-shadow: 0 rem(5px) rem(5px) rgba(33, 33, 33, 0.4);
  }
}

label.feast_it_says--checkbox_button {
  display: inline-block;
  cursor: pointer;
  background-color: #999aa9;
  color: white;
  padding: rem(5px) rem(20px);
  font-weight: 400;
  width: rem(185px);
  text-align: center;
  margin-top: rem(20px);
}
