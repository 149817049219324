.supplier__items {
  display: flex;
  flex-direction: column;
  min-height: 50px;
}

.supplier__items--all {
  display: flex;
  padding: 20px;
  flex-direction: column;
  background: $grey-medium;
}

.supplier__group {
  background: $grey-light;
  margin: 20px 0;
  padding: 20px;
  display: flex;
  flex-direction: column;

  label {
    padding-bottom: 10px;
  }

  &--drag {
    box-shadow: 0 rem(2px) rem(8px) rem(4px) rgba(#999ba8, 0.3);
  }
}

.supplier__item {
  background: $white;
  box-shadow: 0 rem(2px) rem(8px) rem(4px) rgba(#999ba8, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;

  .supplier__item-column {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
  }

  .supplier__item-column--right {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .button + .button {
      margin: 0;
    }
  }

  .supplier__user-column--right {
    display: flex;
    margin-left: auto;
  }

  h4 {
    margin-bottom: rem(5px);
  }

  img {
    border-radius: 50%;
  }
}

.supplier__group--add {
  display: flex;
  flex-direction: column;

  input {
    margin-bottom: 10px;
  }
}

.supplier__users {
  border: 1px solid $grey-medium;
  width: 100%;

  td,
  tr,
  th {
    border: 1px solid $grey-medium;
    text-align: center;
    padding: 10px;
  }
}
