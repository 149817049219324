.tag-group {
  background: white;
  display: block;
  position: relative;
  padding: 20px;
}

.tag-item {
  margin-right: 10px;
  margin-bottom: 10px;
}
.tag-item span {
  font-weight: bold;
  padding: 5px;
}

.tag-item .icon {
  display: block;
  opacity: 0.35; // idea :hover colour change using fill: currentColor to manipulate SVG.
  width: 16px;
  height: 16px;
  margin: 7px 0;
}

.tag-item:hover {
  .icon {
    opacity: 1;
  }
}

.new-tag {
  padding-top: 24px;
  margin-left: -25px;
}

.row--padded-m {
  padding: 20px 0;
}

.new-tag-input {
  margin-left: -15px;
}
