.button {
  border-radius: 2px;
  height: rem(40px);
  border: none;
  padding: 0 rem(26px);
  display: inline-flex;
  align-items: center;
  transition: all ease $transition-short;
  justify-content: center;
  line-height: 1em;
  cursor: pointer;

  svg {
    height: rem(15px);
    width: rem(15px);
    margin-right: rem(8px);
  }

  margin-bottom: 5px;

  & + & {
    margin-left: 10px;
  }
}

.button__spinner {
  position: absolute;
  transition: all ease 0.2s 0.2s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.7);

  .spinner {
    animation-play-state: paused;
  }

  .spinner__rotating {
    animation-play-state: paused;
  }

  svg {
    height: rem(22px);
    width: rem(22px);
    margin: 0;
  }
}

.button__content {
  transition: ease 0.2s 0.4s;
  transition-property: transform, opacity;
  display: inline-flex;
  align-items: center;
  margin: auto;
}

.button--loading {
  .button__content {
    transition: ease 0.2s 0.2s;
    transition-property: transform, opacity;
    transform: scale(0.7);
    opacity: 0;
  }

  .button__spinner {
    transition: ease 0.2s 0.4s;
    transition-property: transform, opacity;
    transform: scale(1);
    opacity: 1;
    visibility: visible;

    .spinner {
      animation-play-state: running;
    }

    .spinner__rotating {
      animation-play-state: running;
    }
  }

  &[disabled] {
    opacity: 1;
    cursor: default;
  }
}

.button--blue {
  @include link-colors($white, $white, $white, $white);
  background: $blue-medium;

  &:active,
  &:hover {
    background: darken(desaturate($blue-medium, 19), 6);
  }

  // &:active,
  // &:hover {
  //   box-shadow: 0 2px darken(desaturate(adjust-hue($blue-medium, 14deg), 39), 24);
  // }
}

.button--green {
  @include link-colors($white, $white, $white, $white);
  background: $green;

  &:active,
  &:hover {
    background: #12c876;
  }

  &:disabled {
    background: #b6e2ce;
  }

  // &:active,
  // &:hover {
  //   box-shadow: 0 2px #00B762;
  // }
}

.button--yellow {
  @include link-colors($white, $white, $white, $white);
  background: $yellow;

  &:active,
  &:hover {
    background: #ce8d00;
  }
}

.button--white {
  @include link-colors($blue-medium, $blue-medium, $blue-medium, $blue-medium);
  background: $white;

  &:active {
    background: #f3f3f3;
  }

  // &:active,
  // &:hover {
  //   box-shadow: 0 2px #92A8F1;
  // }
}

.button--grey {
  @include link-colors($white, $white, $white, $white);
  background: $grey-dark;

  &:active {
    background: darken($grey-dark, 10%);
  }
}

.button--grey-light {
  background: darken($grey-light, 10%);

  &:active,
  &:hover {
    background: darken($grey-light, 20%);
  }
}

.button--transparent {
  @include link-colors($grey-dark, $grey-dark, $grey-dark, $grey-dark);
  background: $white;
  border: 1px solid $grey-dark;

  &:active {
    background: $grey-light;
  }
}

.button--red {
  @include link-colors($white, $white, $white, $white);
  background: $red;

  &:active,
  &:hover {
    background: darken($red, 20%);
  }
}
