%container,
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: rem($bleed);
  padding-right: rem($bleed);
  max-width: rem(($total-width + ($bleed * 2)));
  width: 100%;
}

%container--narrow {
  max-width: rem((340px + ($bleed * 2)));
}
