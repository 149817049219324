.header {
  z-index: index($z-index, header);
  position: relative;
}

.header--padding {
  padding-left: 45px;
  @include breakpoint($desktop) {
    padding-left: 0px;
  }
}

.header--transparent {
  .icon--logo {
    color: $white;
  }

  .header__nav-item {
    @include link-colors($white, $white, $white, $white);
  }
}

.header--white {
  .icon--logo {
    color: $blue-dark;
  }
  background: $white;

  .header__nav-item {
    @include link-colors($blue-dark, $blue-dark, $blue-dark, $blue-dark);
  }
}

.header--blue {
  background: $blue-medium;

  .icon--logo {
    color: $white;
  }

  .header__nav-item {
    @include link-colors($white, $white, $white, $white);
  }
}

.header__inner {
  @extend %container;
  display: flex;
  height: rem(60px);
  justify-content: space-between;
  align-items: center;
}

.header__nav {
  display: flex;
  align-items: center;

  @include breakpoint(0 $phablet) {
    display: none;
  }
}

.header__nav-item:not(:last-child) {
  margin-right: rem(30px);
}

.header__cta {
  @extend .u-unstyled-btn;
  display: flex;
  align-items: center;
  outline: none;
  padding-right: rem(15px);
  position: relative;
  color: currentColor;

  &::after {
    @extend %pseudo-element;
    top: rem(14px);
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 0;
    border-color: currentColor transparent transparent;
  }
}

// Prevents the CTA from reopening after
// clicking it by clicking outside the dropdown!!
.header__cta--disabled {
  pointer-events: none;
}

.header__cta-text {
  margin-right: rem(10px);
}
