.magazine {
  @extend %container;
}

.magazine__inner {
  @extend %grid;
  @extend %unstyled-list;
  @include breakpoint(0 $laptop) {
    margin-top: rem(40px);
  }
  @include breakpoint($laptop) {
    margin-top: rem(80px);
  }
}

.magazine-item__category {
  font-size: em(14px);
  color: $blue-medium;
  font-weight: 900;
}
