.react-bs-table-container {
  background: white;
}

.react-bs-table-container .table > tbody > tr > td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.react-bs-table {
  margin-left: 0px;
  margin-right: 0px;
}
