.missing {
  border-top: 1px solid $grey-medium;
  text-align: center;
  padding: rem(80px) rem(15px);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.missing__img {
  margin-bottom: rem(30px);
  width: 62%;
  max-width: rem(340px);
  @include breakpoint($phablet $tablet) {
    width: 50%;
  }
  @include breakpoint($tablet) {
    width: 33%;
  }
}
