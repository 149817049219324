.mailchimp {
  width: 100%;
  @include breakpoint($tablet-portrait) {
    width: rem(350px);
    display: flex;
    margin-bottom: $baseline;
  }

  .button {
    box-shadow: 0 1px $grey-dark;
    border-bottom-left-radius: 0;
    @include breakpoint(max-width $tablet-portrait) {
      width: 100%;
    }
  }
}

.mailchimp__email[type='email'] {
  flex: 1;
  font-size: em(14px);
  color: $grey-dark;
  border-bottom-color: $grey-dark;
  padding-right: rem(10px);
  @include breakpoint(max-width $tablet-portrait) {
    width: 100%;
    margin-bottom: rem(10px);
  }
}
