body {
  font-family: 'adelle-sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  // font-family: "Walsheim";
  color: $blue-dark;
}

.h1,
h1 {
  font-size: em(32px);
  line-height: rem(40px);
  letter-spacing: 0.5px;
  @include breakpoint($phablet) {
    font-size: em(42px);
    line-height: rem(50px);
  }
}

.h2,
h2 {
  font-size: em(22px);
  line-height: rem(27px);
  margin-bottom: rem(15px);
  @include breakpoint($phablet) {
    line-height: rem(44px);
    margin-bottom: rem(28px);
    font-size: em(36px);
  }
}

.h3,
h3 {
  color: $blue-dark;
  font-weight: 900;
  font-size: em(22px);
  margin-bottom: rem(15px);
  line-height: rem(27px);
  @include breakpoint($phablet) {
    font-size: em(32px);
    line-height: rem(40px);
    margin-bottom: rem(28px);
  }
}

.h4,
h4 {
  color: $grey-dark;
  font-size: 1em;
  margin-bottom: rem(10px);
  @include breakpoint($phablet) {
    font-size: em(26px);
    margin-bottom: rem(28px);
  }
}

.h5,
h5 {
  font-size: em(22px);
  margin-bottom: rem(25px);
  p {
    color: $black;
  }
}

.h6,
h6 {
  color: $grey-dark;
  font-size: em(16px);
  margin-bottom: rem(10px);
}

p {
  color: $grey-dark;
  margin-top: 0;
  margin-bottom: rem(15px);
}

.p--small {
  font-size: em(14px);
}

.t--bold {
  font-weight: 900;
}

.m-t-sm {
  margin-top: rem(20px);
}
