.icon--logo {
  color: $blue-dark;
  height: rem(30px);
  width: rem(95px);
}

.icon--marque {
  color: $blue-dark;
  height: rem(83px);
  width: rem(88px);
}

.icon--hamburger {
  color: $white;
  width: rem(25px);
  height: rem(15px);

  .header--white & {
    color: $blue-dark;
  }
}

.icon--close {
  color: $grey-dark;
  width: rem(15px);
  height: rem(15px);
}

.icon--social {
  height: rem(18px);
  width: rem(18px);
}

.ui--share {
  background: $blue-medium;
  height: rem(50px);
  width: rem(50px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon--share {
  height: rem(20px);
  width: rem(20px);
}

.icon--cutlery {
  height: rem(18px);
  width: rem(14px);
  display: inline-block;
  vertical-align: middle;
}

.icon--thumb {
  display: inline-block;
  height: rem(14px);
  width: rem(14px);
}

.icon--thumb-up {
  color: $green;
}

.icon--thumb-down {
  color: $red;
  transform: rotate(180deg) translateY(rem(-2px));
  transform-origin: rem(8px);
}
