.article {
  @extend %container;
  padding-top: rem(100px);
  border-top: rem(5px) solid $blue-medium;
  margin-top: rem(-35px);
  background: $white;
  position: relative;
  z-index: 10;
}

.article__content {
  @include columns(8);
  margin: 0 auto;
  position: relative;

  .ui--share {
    position: absolute;
    left: -16.0625%;
  }

  img {
    margin-bottom: $baseline;
  }
}
