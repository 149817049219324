.input--area {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.upload--page {
  min-height: 85vh;
}

.upload--field {
  margin: 40px 0;
}

.image--input {
  margin-top: 25px;
}

.image--area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.copy--url {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
}

.image--submit {
  display: flex;
  justify-content: center;
}

.event--image {
  max-height: 320px;
}
