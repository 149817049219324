.menu-item {
  display: flex;
  justify-content: space-between;
  background: $white;
  border: 1px solid $blue-light;
  margin-bottom: rem(20px);
}

.menu-item__meta {
  padding: rem(20px);
}

.menu-item__thumb {
  width: rem(130px);
  height: rem(130px);
  @include breakpoint(max-width $phablet) {
    display: none;
  }
}

.menu-item__title {
  color: $blue-dark;
  // font-family: "Walsheim";
  margin-bottom: rem(15px);
  font-size: em(18px);
}

.menu-item__desc {
  font-size: em(14px);
  line-height: rem(20px);
  margin-bottom: 0;
}
