.pagination {
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin: rem(30px) auto;
}

.pagination__item {
  @include link-colors($grey-dark, $blue-medium, $grey-dark, $grey-dark);
  display: block;
  padding: rem(10px);
}

.pagination__item--active {
  @include link-colors($blue-dark, $blue-dark, $blue-dark, $blue-dark);
}

.pagination__item--ellipsis {
  pointer-events: none;
}

span.pagination__item:hover {
  color: $grey-dark;
}

.hideit {
  visibility: hidden;
}
