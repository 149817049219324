.dropdown {
  margin-top: rem(12px);
  position: absolute;
  background: $white;
  width: rem(220px);
  right: rem(5px);
  border: 1px solid $grey-medium;
  box-shadow: rem(2px) rem(2px) rem(2px) 0 rgba($blue-dark, 0.2);
  transition: all ease $transition-short;
  z-index: index($z-index, dropdown);

  &::before {
    @extend %pseudo-element;
    height: rem(16px);
    width: rem(16px);
    right: rem(16px);
    border-top: 1px solid $grey-medium;
    border-right: 1px solid $grey-medium;
    top: rem(-8px);
    background: $white;
    transform: rotate(-45deg);
  }
}

.dropdown__item {
  font-size: rem(13px);
  padding-left: rem(20px);
  line-height: rem(50px);
  display: block;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-light;
  }
}

.dropdown--hidden {
  pointer-events: none;
  opacity: 0;
  transform: translateY(rem(10px));
}
