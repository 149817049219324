.search {
  @extend %container;
  padding-top: rem(20px);
  padding-bottom: rem(20px);
  @include breakpoint($tablet) {
    display: flex;
    justify-content: center;
  }

  input {
    border-bottom-color: currentColor;
    @include breakpoint($tablet) {
      width: rem(250px);
    }
  }
  @include breakpoint(0 $tablet) {
    > *:not(:last-child) {
      margin-bottom: rem(15px);
    }

    .button {
      width: 100%;
    }
  }
  @include breakpoint($tablet) {
    > *:not(:last-child) {
      margin-right: rem(30px);
    }
  }
}

.search__input--white {
  color: $white;
}

.search__input--blue {
  color: $blue-dark;
}

.search__input--invalid {
  color: $red;
}
