.postcode-to-borough {
  .postcode {
    &.is-hidden {
      opacity: 0;
    }
  }
}

.postcode-to-borough__borough {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  &.is-hidden {
    opacity: 0;
  }
}
