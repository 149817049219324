.instagram {
  img {
    pointer-events: none;
  }
}

.instagram__bookend {
  background: $blue-medium;
  height: rem(100px);
  color: $white;
  margin: 0 auto;
  max-width: rem(400px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  flex-direction: column;

  .icon--instagram {
    margin-bottom: rem(5px);
    width: rem(24px);
    height: rem(24px);
  }

  a {
    @include link-colors($white, $white, $white, $white);
  }
}

.instagram__images {
  background: $blue-light;
}
