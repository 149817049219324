.recommendations {
  @include breakpoint($phablet) {
    margin: rem(60px) auto;
    width: 80%;
  }

  h3 {
    margin: rem(60px) auto;
  }
}

.recommendations__item {
  padding: rem(10px);
  @include breakpoint(0px $phablet) {
    @include columns(6);
  }
  @include breakpoint($phablet $tablet) {
    @include columns(4);
  }
  @include breakpoint($tablet) {
    @include columns(2);
  }
}

.recommendations__title {
  color: $grey-dark;
  text-align: center;
  font-size: em(16px);
  margin: rem(70px) 0 rem(50px);
}
