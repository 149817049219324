.services {
  @extend %container;
  padding-top: rem(50px);
  padding-bottom: rem(50px);
  display: flex;
  @include breakpoint(0 1270px) {
    flex-direction: column;
    align-items: center;
  }
  @include breakpoint(1270px) {
    padding-top: rem(115px);
    padding-bottom: rem(115px);
    justify-content: space-between;
  }
}

.services__item {
  text-align: center;
  max-width: rem(500px);
  @include breakpoint(0 1270px) {
    &:not(:last-child) {
      margin-bottom: rem(40px);
    }
  }
  @include breakpoint(1270px) {
    @include columns(4);
  }
}

.services__img {
  height: rem(80px);
  margin-bottom: rem(35px);
}
