.field {
  margin: rem(10px) 0 rem(25px);
  position: relative;
}

.field__label {
  color: $grey-dark;
  display: block;
  transition: all $transition-medium ease;
  pointer-events: none;
  font-size: rem(16px);
  margin-right: rem(4px);
}

.field__label--after {
  margin-top: rem(-15px);
  margin-bottom: rem(20px);
  font-size: rem(13px);
}

.field__input {
  width: 100%;
  transition: border $transition-medium ease;
}

.field-group {
  display: flex;
}

.field__input--error {
  border-bottom-color: $red;
}

.field__label {
  position: absolute;
  line-height: rem(40px);
}

.field__label.form-control-label {
  position: relative;
  font-size: em(13px);
  line-height: rem(20px);
  &--prices {
    margin-top: 15px;
  }
}

.field__label--touched:not(.form-control-label) {
  transform: translateY(-25px);
  font-size: em(13px);
}

.field__error {
  color: $red;
  font-size: em(13px);
  margin-top: em(5px);
}

.field--rating {
  margin-bottom: rem(16px);
}

.field--status {
  color: $red;

  &.authenticated {
    color: $green;
  }
}

.field--source {
  margin-left: rem(32px);
  text-transform: capitalize;
}
