.view--supplier {
  .images-thumbnails__images {
    flex-flow: row wrap;

    .icon {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
