.spinner {
  animation: rotate 2s linear infinite;
  width: rem(40px);
  height: rem(40px);
  margin: rem(80px) auto;
}

.spinner__static {
  stroke: #dedfe3;
}

.spinner__rotating {
  stroke: #3f6ef0;
  animation: dash 4s ease-in-out infinite;
}
