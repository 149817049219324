@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -126;
  }
}
@keyframes tick {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  62% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
    opacity: 1;
  }
}
