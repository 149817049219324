.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  img:nth-child(1),
  img:nth-child(2) {
    width: calc(50% - #{rem(4px)});
    margin-bottom: rem(8px);
  }

  img:nth-child(3),
  img:nth-child(4),
  img:nth-child(5) {
    width: calc(33.33333% - #{rem(6px)});
  }
}
