.vendor-card {
  display: block;
  position: relative;
  background: $white;
  border: 1px solid #eef3f6;
  transition: all $transition-medium ease;
  margin-bottom: rem(20px);

  &:hover {
    box-shadow: 0 rem(2px) rem(8px) rem(4px) rgba(#999ba8, 0.3);
  }
  @include breakpoint($mobile $phablet) {
    @include columns(6);
  }
  @include breakpoint($phablet $laptop) {
    @include columns(4);
  }
  @include breakpoint($laptop) {
    @include columns(3);
    margin-bottom: rem(40px);
  }
}

.vendor-card__price {
  background: $blue-dark;
  color: $white;
  position: absolute;
  top: 0;
  right: rem(10px);
  height: rem(40px);
  width: rem(40px);
  line-height: rem(40px);
  text-align: center;
  font-weight: 900;
  transition: background $transition-medium ease;

  .vendor-card:hover & {
    background: $blue-medium;
  }
}

.vendor-card__title {
  font-size: em(18px);
  margin-bottom: rem(0px);
  line-height: rem(22px);
}

.vendor-card__meta {
  padding: rem(15px) 0 rem(15px) rem(15px);
}

.vendor-card__img {
  margin: 0;
  background: $blue-light;
  height: 0;
  padding-bottom: 60.3%;
}
