.calendar {
  height: 600px;
  margin-bottom: 60px;
}

.calendar-item {
  &--booked {
    background: $blue-medium !important;
  }

  &--available {
    background: $green !important;
  }

  &--unavailable {
    background: $red !important;
  }

  &--ask {
    background: $yellow !important;
  }
}

.calendar-form {
  margin-top: 30px;
}

.calendar-buttons--group {
  display: flex;
}
