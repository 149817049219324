.user--header {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.user--header--buttons {
  margin-left: auto;

  * {
    margin-left: 8px;
  }
}

.user--row {
  margin-bottom: 32px;
}

.user--row--item-heading {
  font-weight: bold;
  margin-top: 16px;
}
