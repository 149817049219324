.text-editor {
  .ql-editor {
    min-height: 165px;

    p {
      color: $blue-dark;
      margin-bottom: 16px;
    }

    li {
      font-size: 1rem;
    }

    ul,
    ol {
      margin-bottom: 16px;
    }
  }

  .ql-editor ol,
  .ql-editor ul {
    padding-left: 0;
  }
}
