@use "sass:math";

// Link Colours
@mixin link-colors(
  $normal,
  $hover: false,
  $active: false,
  $visited: false,
  $focus: false
) {
  color: $normal;
  @if $visited {
    &:visited {
      color: $visited;
    }
  }
  @if $focus {
    &:focus {
      color: $focus;
    }
  }
  @if $hover {
    &:hover {
      color: $hover;
    }
  }
  @if $active {
    &:active {
      color: $active;
    }
  }
}

// Font Face
@mixin font-face($family, $src, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$src}.eot'); // IE9 compat
    src: url('#{$src}.eot?#iefix') format('embedded-opentype'),
      // IE8 and below
      url('#{$src}.woff') format('woff'),
      // standards
      url('#{$src}.ttf') format('truetype'); // Safari, Android, iOS
    font-style: $style;
    font-weight: $weight;
  }
}

// Convert pixels to rems
$em-base: 16px !default;
@function strip-units($value) {
  @return $value;
}
@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  $base: $em-base;
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return math.div($pxval, $base) * 1rem;
}

// Convert pixels to ems
$em-base: 16px !default;
@function strip-units($value) {
  @return math.div($value, $value * 0 + 1);
}
@function em($pxval, $base: $em-base) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return math.div($pxval, $base) * 1em;
}
