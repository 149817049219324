.quote-wrapper {
  margin-top: 60px;
  padding: 0 30px 0 30px;
}

.quote {
  position: relative;
  border-top: 1px solid $black;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  @include breakpoint($tablet-landscape) {
    padding-bottom: 0;
  }
}

.quote__top {
  .quote__field-vendor-name {
    h5 {
      font-weight: 900;
    }
  }
}

.quote__bottom {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .quote__button {
    position: relative;
    &--edit {
      margin-right: 10px;
    }
  }
}

.quote__field {
  width: 50%;
  @include breakpoint($phablet) {
    width: 33%;
  }
  @include breakpoint($tablet-landscape) {
    width: 10%;
  }
  &--red {
    color: $red;
  }
  &--green {
    color: $green;
  }
  &--black {
    color: $black;
  }
}
