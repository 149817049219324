.avatar {
  border-radius: 50%;
}

.avatar--big {
  width: rem(68px);
  height: rem(68px);
  margin-bottom: $baseline * 2;
}

.avatar--small {
  width: rem(30px);
  height: rem(30px);
}
