.sidebar-menu {
  z-index: 4;
}

.bm-menu {
  background: #373a47;

  a {
    color: #b8b7ad;

    &:hover,
    &:focus {
      color: #c94e50;
    }
  }
}

.bm-item-list a {
  padding: 0.8em;

  span {
    margin-left: 10px;
    font-weight: 700;
  }
}

//
// Burger menu custom styles
//
.bm-burger-button {
  position: absolute;
  width: 25px;
  height: 15px;
  left: 15px;
  top: 13px;
  padding: 10px;
  box-sizing: content-box;

  > span {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.right .bm-burger-button {
  left: initial;
  right: 36px;
}

.bm-burger-bars {
  background: #373a47;
}

.menu-2 {
  .bm-cross {
    background: #999;
  }

  .bm-menu {
    padding: 3.4em 1em 0;
  }

  a {
    padding: 1em;
  }

  i {
    font-size: 1.7em;
    vertical-align: middle;
    color: #282a35;
  }
}

.sb-menu-item {
  display: block;
}
