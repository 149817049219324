.row--border {
  border-bottom: 1px solid $blue-light;
}

.row--white {
  background: $white;
}

.row--grey {
  background: $grey-light;
}

.row--padded-s {
  padding: rem(30px) 0;
}

.row--padded-m {
  padding: rem(30px) 0;
  @include breakpoint($tablet) {
    padding: rem(60px) 0;
  }
}

.row--blue {
  h1 {
    color: $white;
  }
  background: $blue-medium url('../../images/illustrations/motion.svg')
    no-repeat 62% center / contain;
  @include breakpoint(1000px $laptop) {
    background-position-x: 100%;
  }
  @include breakpoint(600px 1000px) {
    background-position-x: 175%;
  }
  @include breakpoint(max-width 700px) {
    background-image: none;
  }
}

.row--padded-l {
  padding: rem(50px) 0;
  @include breakpoint($tablet) {
    padding: rem(75px) 0;
  }
}
