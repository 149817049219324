@use "sass:math";

.checkbox {
  input {
    display: none;

    & + label {
      position: relative;
      margin-bottom: $baseline;
      display: block;
      display: flex;
      padding-left: 0;
      align-items: center;
      text-transform: capitalize;

      &:before,
      svg {
        display: block;
        height: rem(20px);
        width: rem(20px);
        margin-right: math.div(rem(20px), 2);
        content: '';
      }

      &:before {
        border: 1px solid $grey-medium;
      }

      svg {
        color: $blue-medium;
        position: absolute;
        left: 0;
        top: rem(4px);
        background-size: contain;
        opacity: 0;
        transform: translate3d(0, 0, 0);
      }
    }

    &:checked + label svg {
      opacity: 1;
    }
  }
}

.radio + label {
  &:before {
    border-radius: 50%;
  }

  &:after {
    background: $blue-dark;
    border-radius: 50%;
  }
}
