// Colors
$blue-light: #dde5ea;
$blue-medium: #345ff2;
$blue-dark: #343851;
$grey-dark: #999ba8;
$grey-medium: #d6d7dc;
$grey-light: #f6f6f6;
$green: #14d37d;
$red: #ff5a5f;
$white: #fff;
$black: #000;
$yellow: #fcb610;

// Grid settings
$total-width: 1280;
$min-width: 320;
$col: 70;
$gutter: 40;
$bleed: 20;

// Measurements
$baseline: 1.375rem;
$booking-widget-width: 290px;

// Breakpoints
$mobile: 480px;
$phablet: 640px;
$tablet-portrait: 720px;
$tablet-landscape: 1024px;
$tablet: 880px;
$laptop: 1200px;
$desktop: 1500px;
$widescreen: 1800px;

// Transition Speeds
$transition-short: 0.125s;
$transition-medium: 0.25s;
$transition-long: 0.5s;

// z-index stack
$z-index: magazine-meta, hero, header, aside, dropdown, shade, mobile-menu,
  modal;
