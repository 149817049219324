.hero {
  margin-top: rem(-60px);
  position: relative;
  padding-top: rem(60px);
  background: url('../../images/misc/hero.jpg') no-repeat center / cover;
  color: $white;
  @include breakpoint(0 $tablet) {
    padding-top: rem(100px);
    padding-bottom: rem(40px);
  }
  @include breakpoint($tablet) {
    height: rem(610px);
  }

  > * {
    z-index: index($z-index, hero);
  }

  &:after {
    @extend %pseudo-element;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.hero__subtitle,
.hero__title {
  color: $white;
}

.hero--shade-light {
  &:after {
    background: rgba($blue-medium, 0.8);
  }

  .hero__footer {
    background: $white;
  }
}

.hero--shade-dark:after {
  background: rgba($blue-dark, 0.8);
}

.hero__header {
  @extend %container;
  @include breakpoint(0 $tablet) {
    margin-bottom: rem(40px);
  }
  @include breakpoint($tablet) {
    max-width: 40rem;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.hero__subtitle {
  font-weight: 900;
  margin-bottom: rem(40px);
}

.hero__content {
  @extend %container;

  @include breakpoint($tablet) {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.hero__footer {
  @include breakpoint($tablet) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
