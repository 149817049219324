.booking-card {
  display: flex;
  background: $white;
  border-radius: 2px;
  border: 1px solid $blue-light;
  align-items: center;
  width: 100%;
  padding: rem(20px);
  margin-bottom: rem(20px);
  @include breakpoint(max-width $tablet-landscape) {
    flex-direction: column;
    text-align: center;
  }
  @include breakpoint($tablet-landscape) {
    padding: rem(30px);
    margin-bottom: rem(40px);
  }
  @include breakpoint($phablet) {
    .grid & {
      @include columns(6);
    }
  }

  .button {
    @include breakpoint(max-width $tablet-landscape) {
      margin-top: rem(10px);
    }
    @include breakpoint($tablet-landscape + 1) {
      margin-left: auto;
    }
  }
}

.booking-card__title {
  font-size: em(22px);
  margin-bottom: rem(5px);
  line-height: 1.6875rem;
}

.booking-card__subtitle {
  color: $grey-dark;
  font-size: em(16px);
  margin-bottom: rem(5px);
}

.booking-card__meta {
  font-size: em(14px);
  margin-bottom: 0;
}

.booking-card__status {
  color: $white;
  border-radius: 50%;
  @include breakpoint(max-width $tablet-landscape) {
    padding: rem(7px);
    width: rem(36px);
    height: rem(36px);
    margin-bottom: rem(10px);
  }
  @include breakpoint($tablet-landscape) {
    padding: rem(9px);
    width: rem(48px);
    height: rem(48px);
    margin-right: rem(20px);
  }
}

.booking-card__status--pending {
  background: $yellow;
}

.booking-card__status--approved {
  background: $green;
}

.booking-card__status--declined {
  background: $red;
}

.booking-card__status--past {
  background: $grey-medium;
}
