.chevron-link {
  @include link-colors(
    $blue-medium,
    darken(desaturate($blue-medium, 19), 6),
    $blue-medium,
    $blue-medium
  );
  font-weight: 900;
  position: relative;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  transition: color $transition-medium ease;

  &::after {
    margin-left: rem(7px);
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: rem(6px) 0 rem(6px) rem(7px);
    content: '';
    border-color: transparent transparent transparent currentColor;
  }
}

.chevron-link--grey {
  @include link-colors($grey-dark, $blue-medium, $grey-dark, $grey-dark);
}
