// Approach with caution. There were no designs for tablet,
// so all of this is an attempt to get it working nicely
// accross all devices!

$mobile-footer: 720px;

.footer {
  border-top: 1px solid $blue-light;
  padding: 10px 0;
  margin: 0 auto;
}

.footer__inner {
  @extend %container;
  @include breakpoint($mobile-footer) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @include breakpoint(max-width $mobile-footer) {
    max-width: rem(320px);
  }
}

.footer__marque {
  @include breakpoint(max-width $mobile-footer) {
    display: none;
  }
}

.footer__logo {
  svg {
    margin: rem(30px) auto 0;
  }
  @include breakpoint($mobile-footer) {
    display: none;
  }
}

.footer__links {
  @include breakpoint($mobile-footer) {
    columns: 2;
    margin-left: rem(35px);
    font-size: em(14px);
  }
  @include breakpoint(max-width $mobile-footer) {
    width: 100%;
    font-size: em(18px);
    line-height: rem(40px);
  }

  a {
    @include link-colors($grey-dark, $blue-medium, $grey-dark, $grey-dark);
    transition: color ease $transition-short;
    display: block;
    color: $grey-dark;
  }
}

.footer__item:nth-child(1) {
  display: flex;
  align-items: center;
}

.footer__item:nth-child(2) {
  p {
    margin-bottom: 0;
  }
  @include breakpoint($mobile-footer) {
    width: rem(370px);
  }
}

.footer__item:nth-child(3) {
  @include breakpoint(max-width 1100px) {
    margin-top: rem(20px);
    width: 100%;
  }
  @include breakpoint(max-width $mobile-footer) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column-reverse;
  }
}

.footer .social-links,
.footer__item:not(:last-of-type) {
  @include breakpoint(max-width $mobile-footer) {
    padding-bottom: rem(30px);
    border-bottom: 1px solid $grey-light;
    margin-bottom: rem(30px);
  }
}

.footer__item p {
  margin-bottom: 0px;
}

.footer .social-links {
  @include breakpoint(max-width $mobile-footer) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.footer__title {
  color: $blue-dark;
}
