.view--review {
  .meta {
    .pull-left {
      margin: 10px 15px 10px 0;
    }
  }

  .form-buttons {
    display: flex;
    justify-content: space-between;
  }
}

.view--reviews {
  .source {
    padding: 0 !important;
    text-transform: capitalize;

    &--booking {
      color: $blue-medium;
    }
    &--admin {
      color: $green;
    }
  }

  .status {
    color: $red;

    &--authenticated {
      color: $green;
    }
  }

  .published {
    color: $red;

    &--yes {
      color: $black;
    }
  }
}
