.booking-widget {
  @include breakpoint($tablet) {
    position: absolute;
    top: rem(610px);
    left: 0;
    width: 100%;
  }

  input {
    width: 100%;
  }
}

.booking-widget__inner {
  @extend %container;
  @include breakpoint($tablet) {
    position: relative;

    > * {
      top: 0;
      right: rem(20px);
      position: absolute;
      max-width: rem($booking-widget-width);
    }
  }
}

.booking-widget__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  background: $blue-dark;
  padding: rem(20px);
  @include breakpoint($tablet) {
    position: absolute;
    transform: translateY(-100%);
    width: 100%;
  }
}

.booking-widget__header--error {
  background: $red;
}

.booking-widget__main {
  padding: rem(20px);
  border: 1px solid $blue-light;
  border-top: none;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: rem(20px);
  background: $white;
  @include breakpoint($tablet) {
    box-shadow: 0 2px 40px 0 rgba(153, 155, 168, 0.3);
  }

  .button {
    width: 100%;
  }
}

.booking-widget__price {
  font-size: em(28px);
}

.siderbar__disclaimer {
  text-align: center;
  font-size: em(12px);
  margin: rem(15px) 0 0;
}

.booking-widget__footer {
  border-radius: 2px;
  color: $grey-dark;
  background: $white;
  border: 1px solid $blue-light;
  padding: rem(20px);
  font-size: em(14px);
  margin-bottom: rem(40px);
}
