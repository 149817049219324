.field--supplier {
  &__main {
    position: relative;
  }

  &__list {
    background: white;
    border: 1px solid $blue-light;
    display: flex;
    flex-flow: column;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10;

    &__link {
      cursor: pointer;
      display: block;
      line-height: rem(24px);
      padding: 0 rem(8px);

      &:hover {
        background: $blue-medium;
        color: $white;
        text-decoration: none;
      }
    }
  }
}
