.featured-vendor {
  background: url('../../images/misc/featured.jpg') no-repeat center center /
    cover;
  min-height: 50vh;
  height: rem(550px);
  display: flex;
}

.featured-vendor__inner {
  @extend %container;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @include breakpoint($phablet) {
    align-items: center;
  }
}

.featured-vendor__card {
  padding: rem(15px);
  background: $white;
  width: 100%;
  @include breakpoint(0 $phablet) {
    margin-bottom: rem(40px);
  }
  @include breakpoint($phablet) {
    padding: rem(40px);
    width: 50%;
  }
}

.featured-vendor__teaser {
  @include breakpoint(0 $phablet) {
    display: none;
  }
}
