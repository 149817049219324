.view--partner {
  .meta {
    .pull-left {
      margin: 10px 15px 10px 0;
    }
  }

  .form-buttons {
    display: flex;
    justify-content: space-between;
  }
}
