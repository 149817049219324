.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  max-width: rem(400px);
  min-width: rem(290px);
  padding: rem(25px);
  text-align: center;
  z-index: index($z-index, modal);
  transition: all $transition-medium ease;
  @include breakpoint($phablet) {
    padding: rem(50px);
  }
}

.modal__close {
  @include breakpoint(max-width $phablet) {
    display: none;
  }
  @extend .u-touch-btn;
  position: fixed;
  top: rem(-60px);
  right: rem(-60px);

  .icon--close {
    color: $white;
    width: rem(20px);
    height: rem(20px);
  }
}

.modal--hidden {
  pointer-events: none;
  opacity: 0;
  transform: translate(-50%, calc(-50% + #{rem(10px)}));
}
